.hamburger-menu.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
  transform: rotate(-45deg) translate(9px, -9px);
}

.hamburger-menu span {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.menu-items {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  transition: none; /* No transition */
  transform: scaleY(1); /* Default to fully shown */
  height: auto; /* Set the height to auto to avoid cut off */
  opacity: 1; /* Fully opaque */
  visibility: hidden; /* Use visibility to hide/show the menu */
}

.menu-items.opacity-100 {
  visibility: visible; /* Show the menu */
}
